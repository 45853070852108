<template>
  <v-container fluid class="pt-0 pb-0 mt-2">
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="report_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="report_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in report_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" v-if="item.table_name" style="height: 20px !important;">
                    {{ item.table_name }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_number">
                    {{ `№ рядка: ${item.row_number}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.error_text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-row>
      <v-app-bar color="grey lighten-4"
                 elevation="1"
                 style="align-items: center; padding-top: 8px"
                 dense class="pt-0"
                 height="62"
      >
        <v-btn class="mr-2 mini-report-button" @click="save_report">Зберегти</v-btn>
        <v-btn class="mr-2 mini-report-button" @click="generate_report">Заповнити</v-btn>
        <v-select
            :items="report_types" hide-details
            solo color="grey darken-1"
            v-model="header.report_type"
            label="Тип звіту"
            style="flex: 0 0 180px;"
            class="mr-2 mini-solo-field"
            @change="onReportTypeChange"
            required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-select
            :items="months" hide-details
            solo color="grey darken-1"
            v-model="header.month"
            label="Місяць"
            style="flex: 0 0 140px;"
            class="mr-2 mini-solo-field"
            required :rules="[v => !!v || 'Це поле є обов’язковим']"
            @change="onDateChange"
            :disabled="!header.report_type"
        />
        <v-text-field v-model="header.year"
                      hide-details solo color="grey darken-1"
                      label="Рік"
                      style="flex: 0 0 110px;"
                      class="mr-2 mini-solo-field"
                      v-integer
                      @change="onDateChange"
                      :disabled="!header.month"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-text-field v-model="header.number"
                      hide-details solo color="grey darken-1"
                      label="№ розрах."
                      style="flex: 0 0 100px;"
                      class="mr-2 mini-solo-field"
                      v-integer
                      :disabled="!header.year"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
        />
        <v-menu
            top
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2 mr-2 mini-report-button"
                   v-bind="attrs"
                   v-on="on"
            >
              Додатки
            </v-btn>
          </template>

          <v-list dense nav>
            <v-list-item dense>
              <v-list-item-action>
                <v-checkbox color="success"
                            v-model="header.pages.d1.active"
                            @change="getMainPagesCount"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ header.pages.d1.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense>
              <v-list-item-action>
                <v-checkbox color="success"
                            v-model="header.pages.d4.active"
                            @change="getMainPagesCount"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ header.pages.d4.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense>
              <v-list-item-action>
                <v-checkbox color="success"
                            v-model="header.pages.d5.active"
                            @change="getMainPagesCount"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ header.pages.d5.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-menu>
        <v-btn class="mr-2 mini-report-button" @click="export_report_to_xml">XML</v-btn>
        <v-btn class="mr-2 mini-report-button" v-if="!report_is_new" @click="questionForDeleteReport">Вилучити</v-btn>
        <v-menu
            bottom
            :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2 mini-report-button"
                   v-bind="attrs"
                   v-on="on"
            >
              Звіти
            </v-btn>
          </template>

          <v-list dense nav>
            <v-subheader>
              Оберіть необхідний звіт
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item dense style="font-size: .8rem"
                         @click="openReportDialog(
                      'salary_main_report',
                           'Розрахункова відомість',
                           )"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="teal" size="20">
                  mdi-poll
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Розрахункова відомість
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense style="font-size: .8rem"
                         @click="openReportDialog(
                      'salary_contribution_and_taxes',
                           'Відомість внесків та податків',
                           )"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="teal" size="20">
                  mdi-poll
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Відомість внесків та податків
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense style="font-size: .8rem"
                         @click="openReportDialog(
                      'salary_total_contribution_and_taxes',
                           'Підсумкова відомість внесків та податків',
                           )"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="teal" size="20">
                  mdi-poll
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Підсумкова відомість внесків та податків
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item dense style="font-size: .8rem"
                         @click="openReportDialog(
                      'accounting_main_report',
                           'Оборотно-сальдова відомість',
                           )"
            >
              <v-list-item-icon class="mr-4">
                <v-icon color="teal" size="20">
                  mdi-poll
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Оборотно-сальдова відомість
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-row>
    <v-dialog v-model="report_dialog.dialog" fullscreen class="media-print non-printable">
      <PrintReport @closeModal="closeReportDialog"
                   :payload_date_start="this.header.date_start"
                   :payload_date_end="this.header.date_end"
                   :auto_generate="true"
                   :component_name="report_dialog.component_name"/>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-tabs class="custom-tabs without" color="success" style="margin-top: 5px">
          <v-tab>
            Налаштування звіту
          </v-tab>
          <v-tab>
            Декларація
          </v-tab>
          <v-tab v-if="header.pages.d1.active">
            Д1 (1)
          </v-tab>
          <v-tab v-if="header.pages.d4.active">
            Д4 (1)
          </v-tab>
          <v-tab v-if="header.pages.d5.active">
            Д5
          </v-tab>

          <v-tab-item class="pa-0" style="height: 100%;">
            <v-card>
              <v-card-text>
                <v-form v-model="formValid" ref="form">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                          v-model="header.payer_name"
                          label="Платник: "
                          placeholder="Введіть назву платника"
                          filled hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.payer_name ? '' : 'req-star'"
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="header.payer_ipn_or_passport"
                          label="Податковий номер або та номер паспорта платника: "
                          placeholder="Введіть іпн або серію та номер паспорта"
                          filled hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.payer_ipn_or_passport ? '' : 'req-star'"
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="header.payer_city_code"
                          label="КАТОТТГ: "
                          placeholder="Введіть КАТОТТГ"
                          filled hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.payer_city_code ? '' : 'req-star'"
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                          v-model="header.payer_postal_address"
                          label="Податкова адреса: "
                          placeholder="Введіть податкову адресу: "
                          filled hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.payer_postal_address ? '' : 'req-star'"
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="header.payer_zip_code"
                          label="Поштовий індекс: "
                          placeholder="Введіть поштовий індекс: "
                          filled hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.payer_zip_code ? '' : 'req-star'"
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="header.payer_phone"
                          label="Телефон: "
                          placeholder="Введіть телефон: "
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="header.payer_fax"
                          label="Факс: "
                          placeholder="Введіть факс: "
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="header.payer_email"
                          label="Електронна адреса: "
                          placeholder="Введіть електронну адресу: "
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                          v-model="header.controlling_body"
                          label="Назва контролюючого органу: "
                          placeholder="Введіть назву контролюючого органу: "
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.controlling_body ? '' : 'req-star'"
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                          v-model="header.director_ipn"
                          label="ІПН керівника: "
                          placeholder="Введіть іпн керівника: "
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.director_ipn ? '' : 'req-star'"
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                          v-model="header.director_name"
                          label="ПІБ керівника: "
                          placeholder="Введіть ПІБ керівника: "
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="header.director_name ? '' : 'req-star'"
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                          v-model="header.accountant_ipn"
                          label="ІПН бухгалтера: "
                          placeholder="Введіть іпн бухгалтера: "
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                          v-model="header.accountant_name"
                          label="ПІБ бухгалтера: "
                          placeholder="Введіть ПІБ бухгалтера: "
                          filled hide-details
                          color="grey"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item class="pa-0" style="height: calc(100vh - 158px); overflow: auto">
            <table class="regulatory-report-table" style="width: 1000px; margin-top: 18px">
              <tbody>
              <tr>
                <td>
                  06
                </td>
                <td>
                  Інформація про додатки, що додаються до Розрахунку та є його невід'ємною частиною
                </td>
                <td colspan="2" class="text-center">
                  кількість додатків
                </td>
                <td colspan="1" class="text-center">
                  кількість аркушів
                </td>
              </tr>
              <tr>
                <td>
                  061
                </td>
                <td>
                  Відомості про нарахування заробітної плати (доходу, грошового забезпечення)
                  застрахованим особам
                </td>
                <td colspan="2" class="text-center">
                  <template v-if="totals.declaration.d1.active">
                    {{ totals.declaration.d1.count }}
                  </template>
                </td>
                <td colspan="1" class="text-center">
                  <template v-if="totals.declaration.d1.active">
                    {{ totals.declaration.d1.pages }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>
                  062
                </td>
                <td>
                  Відомості про осіб, які доглядають за дитиною до досягнення нею трирічного віку та
                  відповідно до закону отримують допомогу по догляду за дитиною до досягнення нею
                  трирічного віку та/або при народженні дитини, при усиновленні дитини, та осіб із
                  числа непрацюючих працездатних батьків, усиновителів, опікунів, піклувальників, які
                  фактично здійснюють догляд за дитиною з інвалідністю, дитиною, хворою на тяжке
                  перинатальне ураження нервової системи, тяжку вроджену ваду розвитку, рідкісне
                  орфанне захворювання, онкологічне, онкогематологічне захворювання, дитячий
                  церебральний параліч, тяжкий психічний розлад, цукровий діабет I типу
                  (інсулінозалежний), гостре або хронічне захворювання нирок IV ступеня, за дитиною,
                  яка отримала тяжку травму, потребує трансплантації органа, потребує паліативної
                  допомоги, якій не встановлено інвалідність, а також непрацюючих працездатних осіб,
                  які здійснюють догляд за особою з інвалідністю I групи або за особою похилого віку,
                  яка за висновком медичного закладу потребує постійного стороннього догляду або
                  досягла 80-річного віку, якщо такі непрацюючі працездатні особи отримують допомогу,
                  надбавку або компенсацію відповідно до законодавства, та нарахування сум єдиного
                  внеску за патронатних вихователів, батьків-вихователів дитячих будинків сімейного
                  типу, прийомних батьків, якщо вони отримують грошове забезпечення відповідно до
                  законодавства
                </td>
                <td colspan="2"></td>
                <td colspan="1"></td>
              </tr>
              <tr>
                <td>
                  063
                </td>
                <td>
                  Відомості про осіб, які проходять строкову військову службу
                </td>
                <td colspan="2"></td>
                <td colspan="1"></td>
              </tr>
              <tr>
                <td>
                  064
                </td>
                <td>
                  Відомості про суми нарахованого доходу, утриманого та сплаченого податку на доходи
                  фізичних осіб та військового збору
                </td>
                <td colspan="2" class="text-center">
                  <template v-if="totals.declaration.d4.active">
                    {{ totals.declaration.d4.count }}
                  </template>
                </td>
                <td colspan="1" class="text-center">
                  <template v-if="totals.declaration.d4.active">
                    {{ totals.declaration.d4.pages }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>
                  065
                </td>
                <td>
                  Відомості про трудові відносини осіб та період проходження військової служби
                </td>
                <td colspan="2" class="text-center">
                  <template v-if="totals.declaration.d5.active">
                    {{ totals.declaration.d4.count }}
                  </template>
                </td>
                <td colspan="1" class="text-center">
                  <template v-if="totals.declaration.d5.active">
                    {{ totals.declaration.d5.pages }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>
                  066
                </td>
                <td>
                  Відомості про наявність підстав для обліку стажу окремим категоріям осіб відповідно
                  до законодавства
                </td>
                <td colspan="2"></td>
                <td colspan="1"></td>
              </tr>
              <tr>
                <td>
                  10
                </td>
                <td>
                  Штатна чисельність працівників (показники кількості працівників зазначаються в цілих
                  одиницях)
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_all"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_all"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_all"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  101
                </td>
                <td>
                  Середньооблікова кількість штатних працівників за звітний період, осіб
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_all_average"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_all_average"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_all_average"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" class="without-border text-left">
                  у т.ч.
                </td>
              </tr>
              <tr>
                <td>
                  102
                </td>
                <td>
                  працівників, яким відповідно до чинного законодавства встановлено інвалідність, осіб
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_all_average_invalid"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_all_average_invalid"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_all_average_invalid"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  103
                </td>
                <td>
                  працівників, що мають додаткові гарантії в сприянні працевлаштуванню
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_all_add_with_work"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_all_add_with_work"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_all_add_with_work"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  104
                </td>
                <td>
                  Облікова кількість штатних працівників
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_accounting"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  105
                </td>
                <td>
                  Кількість застрахованих осіб у звітному періоді, яким нараховано заробітну плату /
                  (крім осіб, яким у звітному періоді нараховано грошове забезпечення)
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_with_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_with_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_with_accounting"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  106
                </td>
                <td>
                  Кількість застрахованих осіб, яким у звітному періоді нараховано грошове
                  забезпечення
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_with_money_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_with_money_accounting"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_with_money_accounting"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  107
                </td>
                <td>
                  Кількість застрахованих осіб у звітному періоді з числа непрацюючих інших з подружжя
                  працівників дипломатичної служби, яким нараховано єдиний внесок
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_with_diplomaty"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_with_diplomaty"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_with_diplomaty"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  108
                </td>
                <td>
                  Жінок
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_women"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_women"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_women"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  109
                </td>
                <td>
                  Чоловіків
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_man"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_man"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_man"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  110
                </td>
                <td>
                  Кількість створених нових робочих місць у звітному періоді
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_1.staff_new_work_place"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_2.staff_new_work_place"
                         class="regulatory-report-input text-center">
                </td>
                <td class="text-center">
                  <input type="text"
                         v-model="declaration.table_main_3.staff_new_work_place"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" class="without-border text-right">
                  (грн коп.)
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td style="width: 100; max-width: 100px">
                  Код рядка
                </td>
                <td>
                  І. Нарахування доходу та єдиного внеску за найманих працівників в розрізі місяців
                  звітного кварталу
                </td>
                <td style="width: 80px; max-width: 80px;" class="text-center">
                  1
                </td>
                <td style="width: 80px; max-width: 80px" class="text-center">
                  2
                </td>
                <td style="width: 80px; max-width: 80px" class="text-center">
                  3
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>1</td>
                <td>
                  Загальна сума нарахованого доходу, усього (р. 1.1 + р. 1.2 + р. 1.3 + р. 1.4 + р.
                  1.5)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_1 }}
                </td>
              </tr>
              <tr class="without-border">
                <td colspan="5" class="without-border text-left">
                  у т.ч
                </td>
              </tr>
              <tr>
                <td>
                  1.1
                </td>
                <td>
                  сума нарахованої заробітної плати
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.salary_charge"
                         @change="getTotalsMain"
                         class="regulatory-report-input text-center"
                         v-decimal
                  >
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.salary_charge"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.salary_charge"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.2
                </td>
                <td>
                  сума винагороди за договорами цивільно-правового характеру
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.contract_by_cln"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.contract_by_cln"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.contract_by_cln"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.3
                </td>
                <td>
                  сума оплати перших п'яти днів тимчасової непрацездатності,
                  що здійснюється за рахунок коштів платника податків
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.hospital_by_organization"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.hospital_by_organization"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.hospital_by_organization"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.4
                </td>
                <td>
                  сума допомоги по тимчасовій непрацездатності, яка виплачується
                  за рахунок коштів фонду соціального страхування
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.hospital_by_social"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.hospital_by_social"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.hospital_by_social"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.5
                </td>
                <td>
                  сума допомоги у зв'язку з вагітністю та пологами
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.decree"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.decree"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.decree"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.6
                </td>
                <td>
                  сума нарахованої заробітної плати за найманих працівників, призваних під час
                  мобілізації на військову службу до Збройних Сил України, які не отримували доходи у
                  вигляді грошового забезпечення, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_1_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_1_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_1_millitary }}
                </td>
              </tr>
              <tr>
                <td>
                  1.6.1
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.military_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.military_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.military_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.6.2
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.military_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.military_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.military_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  1.6.3
                </td>
                <td>
                  22 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.military_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.military_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.military_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2
                </td>
                <td>
                  Сума нарахованого доходу у межах максимальної величини, на
                  яку нараховується єдиний внесок, усього
                  (р. 2.1 + р. 2.2 + р. 2.3 + р. 2.4 + р. 2.5)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_2 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_2 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_2 }}
                </td>
              </tr>
              <tr>
                <td>
                  2.1
                </td>
                <td>
                  Роботодавцями (22 %)
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_1.max_salary_charge_22"
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_2.max_salary_charge_22"
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_3.max_salary_charge_22"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.2
                </td>
                <td>
                  Підприємствами, установами і організаціями, фізичними особами - підприємцями, у тому
                  числі тими, які обрали спрощену систему оподаткування, працюючим особам з
                  інвалідністю (8,41 %)
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_1.max_salary_charge_841"
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_2.max_salary_charge_841"
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model="declaration.table_main_3.max_salary_charge_841"
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.3
                </td>
                <td>
                  Підприємствами та організаціями всеукраїнських громадських організацій осіб з
                  інвалідністю, зокрема товариств УТОГ, УТОС (5,3 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_charge_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_charge_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_charge_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.4
                </td>
                <td>
                  Підприємствами та організаціями громадських організацій осіб з інвалідністю,
                  працюючим особам з інвалідністю (5,5 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_charge_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_charge_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_charge_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.5
                </td>
                <td>
                  додаткова база нарахування єдиного внеску (22 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_add_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_add_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_add_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.6
                </td>
                <td>
                  за найманих працівників, призваних під час мобілізації на військову службу до
                  Збройних Сил України, які не отримували доходи у вигляді грошового забезпечення11, з
                  них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_2_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_2_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_2_millitary }}
                </td>
              </tr>
              <tr>
                <td>
                  2.6.1
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.6.1
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  2.6.3
                </td>
                <td>
                  22 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.max_salary_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.max_salary_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.max_salary_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  3
                </td>
                <td>
                  Нараховано єдиного внеску (р. 3.1 + р. 3.2 + р. 3.3 + р. 3.4 + р. 3.5 + р. 3.6)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_3 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_3 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_3 }}
                </td>
              </tr>
              <tr>
                <td>
                  3.1
                </td>
                <td>
                  на суми нарахованої роботодавцями заробітної плати, винагороди за виконані роботи
                  (надані послуги) за цивільно-правовими договорами, оплати допомоги по тимчасовій
                  непрацездатності та допомоги у звязку з вагітністю та пологами (22,0 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_main_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_main_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_main_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.2
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами, установами і організаціями, у
                  тому числі фізичними особами - підприємцями, у т. ч. тими, які обрали спрощену
                  систему оподаткування працюючим особам з інвалідністю (8,41 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_main_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_main_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_main_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.2.1
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами, установами і організаціями,
                  працюючим особам з інвалідністю (22 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_invalid_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_invalid_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_invalid_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.3
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами та організаціями всеукраїнських
                  гроамдських організацій осіб з інвалідністю, зокрема товариствами УТОГ та УТОС (5,3
                  %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.3.1
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами та організаціями всеукраїнських
                  громадських організацій осіб з інвалідністю, зокрема товариствами УТОГ та УТОС (22
                  %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_invalid_22_go"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_invalid_22_go"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_invalid_22_go"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.4
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами та організаціями громадських
                  організацій осіб з інвалідністю, працюючим особам з інвалідністю (5,5 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.4.1
                </td>
                <td>
                  на суми заробітної плати, нарахованої підприємствами та організаціями громадських
                  організацій осіб з інвалідністю (за умов, визначених частиною чотирнадцятою статті 8
                  Закону України "Про збір та облік єдиного внеску на загальнообов'язкове державне
                  соціальне страхування"), працюючим особам з інвалідністю (22 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_public_invalid_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_public_invalid_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_public_invalid_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.5
                </td>
                <td>
                  на суми різниці між розміром мінімальної заробітної плати та фактично нарахованої
                  заробітної плати роботодавцями (22,0 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_diff_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_diff_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_diff_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.6
                </td>
                <td>
                  на суми мінімальної заробітної плати непрацюючому іншого з подружжя працівника
                  дипломатичної служби (22 %)
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_diplomat_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_diplomat_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_diplomat_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.7
                </td>
                <td>
                  на суму нарахованої заробітної плати за найманих працівників, призваних під час
                  мобілізації на військову службу до Збройних Сил України, які не отримували доходи у
                  вигляді грошового забезпечення11, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_3_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_3_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_3_millitary }}
                </td>
              </tr>
              <tr>
                <td>
                  3.7.1
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.7.2
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  3.7.3
                </td>
                <td>
                  22 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  4
                </td>
                <td>
                  Донараховано та/або доутримано єдиного внеску у зв'язку з виправленням помилок,
                  допущених в попередніх звітних періодах (р. 4.1 + р. 4.2 + р. 4.3)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_4 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_4 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_4 }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  4.1
                </td>
                <td>
                  Донараховано єдиного внеску у зв'язку з виправленням помилки, допущеної в попередніх
                  звітних періодах, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_4_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_4_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_4_1 }}
                </td>
              </tr>
              <tr>
                <td>
                  4.1.1
                </td>
                <td>
                  8,41%
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.1.2
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.1.3
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.2
                </td>
                <td>
                  Донараховано єдиний внесок за попередні звітні періоди внаслідок збільшення класу
                  професійного ризику
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_class_rise"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_class_rise"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_class_rise"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.3
                </td>
                <td>
                  Додатково утримано єдиного внеску за попередні звітні періоди
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.4
                </td>
                <td>
                  Донараховано єдиного внеску у зв'язку з виправленням помилки, допущеної
                  в попередніх звітних періодах за найманих працівників, призваних під час мобілізації
                  на військову службу до Збройних Сил України, які не отримували доходи у вигляді
                  грошового забезпечення11, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_4_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_4_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_4_millitary }}
                </td>
              </tr>
              <tr>
                <td>
                  4.4.1
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_millitary_53"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_millitary_53"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_millitary_53"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.4.1
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_millitary_55"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_millitary_55"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_millitary_55"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  4.4.1
                </td>
                <td>
                  22 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  5
                </td>
                <td>
                  Сума пені, яка нарахована платником єдиного внеску самостійно відповідно до частини
                  другої статті 25 Закону України "Про збір та облік єдиного внеску на
                  загальнообов'язкове державне соціальне страхування", що підлягає сплаті за звітний
                  квартал (гр. 1 + гр. 2 + гр. 3 рядка 5.1)
                </td>
                <td class="text-center" colspan="3">
                  {{ declaration.table_main_1.total_row_5 }}
                </td>
              </tr>
              <tr>
                <td>
                  5.1
                </td>
                <td>
                  Сума пені, яка нарахована платником єдиного внеску самостійно відповідно до частини
                  другої статті 25 Закону України "Про збір та облік єдиного внеску на
                  загальнообов'язкове державне соціальне страхування", що підлягає сплаті в розрізі
                  місяців звітного кварталу
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_penya"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_penya"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_penya"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  6
                </td>
                <td>
                  Сума, на яку зменшено суму нарахувань та/або утримань з єдиного внеску у зв'язку з
                  виправленням помилки, допущеної в попередніх звітних періодах (р. 6.1 + р. 6.2 + р.
                  6.3)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_6 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_6 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_6 }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  6.1
                </td>
                <td>
                  Зменшено суму нарахувань з єдиного внеску у зв'язку з виправленням помилки,
                  допущеної в попередніх звітних періодах, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_6_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_6_1 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_6_1 }}
                </td>
              </tr>
              <tr>
                <td>
                  6.1.1
                </td>
                <td>
                  8,41%
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_841"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.1.2
                </td>
                <td>
                  5,3%
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.1.3
                </td>
                <td>
                  5,5%
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.2
                </td>
                <td>
                  Зменшено суму єдиного внеску за попередні звітні періоди внаслідок зменшення класу
                  професійного ризику
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_class_decrease"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_class_decrease"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_class_decrease"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.3
                </td>
                <td>
                  Зменшено суму утримань за попередні звітні періоди
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_withdrow"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.4
                </td>
                <td>
                  Зменшено суму нарахувань з єдиного внеску у зв'язку з виправленням помилки,
                  допущеної в попередніх звітних періодах за найманих працівників, призваних під час
                  мобілізації на військову службу до Збройних Сил України, які не отримували доходи у
                  вигляді грошового забезпечення11, з них:
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_6_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_6_millitary }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_6_millitary }}
                </td>
              </tr>
              <tr>
                <td>
                  6.4.1
                </td>
                <td>
                  5,3 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_millitary_5_3"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.4.2
                </td>
                <td>
                  5,5 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_millitary_5_5"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr>
                <td>
                  6.4.2
                </td>
                <td>
                  22 %
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_1.esv_dorah_decrease_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_2.esv_dorah_decrease_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
                <td>
                  <input type="text"
                         v-model.number="declaration.table_main_3.esv_dorah_decrease_millitary_22"
                         @change="getTotalsMain"
                         v-decimal
                         class="regulatory-report-input text-center">
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  7
                </td>
                <td>
                  Загальна сума єдиного внеску, що підлягає сплаті (р. 3 + р. 4 - р. 6)
                </td>
                <td class="text-center">
                  {{ declaration.table_main_1.total_row_7 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_2.total_row_7 }}
                </td>
                <td class="text-center">
                  {{ declaration.table_main_3.total_row_7 }}
                </td>
              </tr>
              <tr class="font-weight-bold">
                <td>
                  8
                </td>
                <td>
                  Загальна сума єдиного внеску, що підлягає сплаті за звітний квартал (гр. 1 + гр. 2 +
                  гр. 3 рядка 7)
                </td>
                <td colspan="3" class="text-center">
                  {{ declaration.table_main_1.total_row_8 }}
                </td>
              </tr>
              </tbody>
            </table>
          </v-tab-item>
          <v-tab-item class="pa-0" style="height: calc(100vh - 158px); overflow: auto" v-if="header.pages.d1.active">
            <table class="regulatory-report-table">
              <caption>
                <div style="font-size: 14px; line-height: 18px; padding: 8px 4px; margin-top: 10px">
                  Додаток 1
                  до Податкового розрахунку сум доходу, нарахованого (сплаченого) <br> на користь
                  платників податків - фізичних осіб, і сум утриманого з них податку, а також сум
                  нарахованого єдиного внеску (Д1)
                  (пункт 1 розділу IV)
                </div>
              </caption>
              <thead>
              <tr>
                <th rowspan="2" style="width: 65px; min-width: 65px; max-width: 65px">
                  4. N з/п
                </th>
                <th rowspan="2" style="width: 65px; min-width: 65px; max-width: 65px">
                  5. Гро-<br>
                  ма-<br>
                  дянин
                  Украї-<br>
                  ни <br>
                  (1 - так,
                  0 - ні)
                </th>
                <th rowspan="1" style="width: 65px; min-width: 65px; max-width: 65px">
                  6. Чоло-<br>
                  вік<br>
                  - Ч,<br>
                  жінка<br>
                  - Ж
                </th>
                <th rowspan="1">
                  7. Реєстраційний номер облікової карти <br>
                  платника податків або серія <br>
                  (за наявності) та/або номер <br>
                  паспорта за формою <br>
                  БК№№XXXXXX / ПXXXXXXXXX ЗО *
                </th>
                <th rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                  8. Код <br>
                  катего <br>
                  -рії <br>
                  ЗО**"
                </th>
                <th rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                  9. Тип <br>
                  нара- <br>
                  хувань <br>
                  ***"
                </th>
                <th rowspan="1" colspan="2" style="width: 80px; min-width: 80px; max-width: 80px">
                  10. Місяць <br>
                  та рік, за <br>
                  який прове- <br>
                  дено нара- <br>
                  хування <br>
                  ****"
                </th>
                <th rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                  12. Кіль <br>
                  кість кален- <br>
                  дарних днів <br>
                  тимчасо- <br>
                  вої непраце -<br>
                  здатності
                </th>
                <th rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                  14. Кількість <br>
                  днів перебу- <br>
                  вання у тру- <br>
                  дових/ЦП <br>
                  відносинах, <br>
                  проходження <br>
                  військової <br>
                  служби протягом <br>
                  календарного звіт- <br>
                  ного місяця
                </th>
                <th rowspan="1" style="width: 130px; min-width: 130px; max-width: 130px">
                  16. Загальна сума <br>
                  нарахованої заробітної <br>
                  плати / доходу, грошо- <br>
                  вого збезпечення <br>
                  (усього з початку <br>
                  звітного місяця)
                </th>
                <th rowspan="2" style="width: 110px; min-width: 110px; max-width: 110px">
                  18. Сума різниці <br>
                  між розміром міні- <br>
                  мальної заробітної <br>
                  плати та фактично <br>
                  нарахованою заробітною <br>
                  платою за звітний <br>
                  місяць (із заробіт- <br>
                  ної плати / доходу <br>
                  / грошового забезпе- <br>
                  чення/) доплата до <br>
                  мінімального страхо- <br>
                  вого внеску або сума <br>
                  мінімальної заробітної <br>
                  плати для категорії <br>
                  застрахованих осіб 72
                </th>
                <th rowspan="1" style="width: 110px; min-width: 110px; max-width: 110px">
                  19. Сума <br>
                  утриманого єдиного <br>
                  внеску за звітний <br>
                  місяць (із заробітної <br>
                  плати / доходу/ <br>
                  грошового забезпечення
                </th>
                <th rowspan="1" style="width: 70px; min-width: 70px; max-width: 70px">
                  21. Ознака <br>
                  наявності <br>
                  трудової <br>
                  книжки <br>
                  (1 - так, <br>
                  0 - ні)
                </th>
                <th rowspan="1" style="width: 70px; min-width: 70px; max-width: 70px">
                  23. Ознака <br>
                  неповного <br>
                  робочого <br>
                  часу <br>
                  (1 - так, <br>
                  0 - ні)"
                </th>
                <th rowspan="2" style="width: 90px; min-width: 90px; max-width: 90px">
                  25. ознака "0", <br>
                  якщо рядок <br>
                  потрібно ввести, <br>
                  чи ознака "1", <br>
                  якщо рядок <br>
                  потрібно ви- <br>
                  ключити. Тільки <br>
                  для "Звітного <br>
                  нового" та <br>
                  "Уточнюючого"
                </th>
                <th rowspan="2" style="width: 90px; min-width: 90px; max-width: 90px">
                  26. Ознака <br>
                  наявності <br>
                  трудового <br>
                  договору з <br>
                  нефіксованим <br>
                  робочим <br>
                  часом <br>
                  (1-так, <br>
                  0-ні)
                </th>
              </tr>
              <tr>
                <th rowspan="1" colspan="6">
                  11. Прізвище, ім'я, по батькові ЗО
                </th>
                <th rowspan="1">
                  13. Кіль <br>
                  кість кален- <br>
                  дарних днів <br>
                  без збере <br>
                  ження заро <br>
                  бітної пла <br>
                  ти*****
                </th>
                <th rowspan="1" style="width: 100px; min-width: 100px; max-width: 100px">
                  15. Кількість <br>
                  календарних <br>
                  днів <br>
                  відпустки у <br>
                  зв’язку з <br>
                  вагітністю та <br>
                  пологами
                </th>
                <th rowspan="1">
                  17. Сума нарахованої <br>
                  заробітної плати <br>
                  / доходу /грошового <br>
                  забезпечення у межах <br>
                  максимальної величини, <br>
                  на яку нараховується <br>
                  єдиний внесок
                </th>
                <th rowspan="1">
                  20. Сума нарахованого <br>
                  єдиного внеску за звіт- <br>
                  ний місяць (на заробітну <br>
                  плату / дохід/ грошове <br>
                  забезпечення)
                </th>
                <th rowspan="1">
                  22. Ознака <br>
                  наявності <br>
                  спецстажу <br>
                  (1 - так, <br>
                  0 - ні)
                </th>
                <th rowspan="1">
                  24. Ознака <br>
                  нового <br>
                  робочого <br>
                  місця <br>
                  (1 - так, <br>
                  0 - ні)
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="row in table_esv_1">
                <tr :key="`esv-1-row-1-${row.row_num}`">
                  <td rowspan="2" style="width: 65px; min-width: 65px; max-width: 65px"
                      class="text-right"
                  >
                    {{ row.row_num }}
                  </td>
                  <td rowspan="2" style="width: 65px; min-width: 65px; max-width: 65px">
                    <input type="text"
                           v-model.number="row.national"
                           v-integer
                           :class="{'error-input': row.national === null || row.national === ''}"
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="1" style="width: 65px; min-width: 65px; max-width: 65px">
                    <input type="text"
                           v-model="row.gender"
                           :class="{'error-input': !row.gender}"
                           class="regulatory-report-input text-center"
                    >
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.ipn"
                           :class="{'error-input': !row.ipn}"
                           class="regulatory-report-input">
                  </td>
                  <td rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                    <input type="text"
                           v-model.number="row.category_code"
                           v-integer
                           :class="{'error-input': !row.category_code}"
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                    <input type="text"
                           v-model="row.charge_type"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="1" colspan="1" style="width: 40px; min-width: 40px; max-width: 40px">
                    <input type="text"
                           v-model.number="row.month_number"
                           v-integer
                           :class="{'error-input': !row.month_number}"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" colspan="1" style="width: 40px; min-width: 40px; max-width: 40px">
                    <input type="text"
                           v-model.number="row.year_number"
                           v-integer
                           :class="{'error-input': !row.year_number}"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                    <input type="text"
                           v-model.number="row.hospital_days"
                           v-integer
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 60px; min-width: 60px; max-width: 60px">
                    <input type="text"
                           v-model.number="row.main_days"
                           v-integer
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 130px; min-width: 130px; max-width: 130px">
                    <input type="text"
                           v-model.number="row.base"
                           v-decimal
                           @change="getTotalsD1_1"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="2" style="width: 110px; min-width: 110px; max-width: 110px">
                    <input type="text"
                           v-model.number="row.base_diff"
                           v-decimal
                           @change="getTotalsD1_1"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 110px; min-width: 110px; max-width: 110px">
                    <input type="text"
                           v-model.number="row.withheld_sum"
                           v-decimal
                           @change="getTotalsD1_1"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 70px; min-width: 70px; max-width: 70px">
                    <input type="text"
                           v-model="row.work_book"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="1" style="width: 70px; min-width: 70px; max-width: 70px">
                    <input type="text"
                           v-model="row.not_full_time"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="2" style="width: 90px; min-width: 90px; max-width: 90px">
                    <input type="text"
                           v-model="row.sign_row"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="2" style="width: 90px; min-width: 90px; max-width: 90px">
                    <input type="text"
                           v-model="row.contract_with_not_full_job"
                           v-integer
                           class="regulatory-report-input text-center">

                    <v-btn small icon style="margin-left: 23px" @click="delete_row_d1_1(row)">
                      <v-icon small color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr :key="`esv-1-row-2-${row.row_num}`">
                  <td rowspan="1" colspan="6" style="padding: 0 !important;">
                    <div class="d-flex flex-nowrap">
                      <div style="flex: 0 0 120px; border-right: 1px solid; padding: 2px 6px;">
                        <input type="text"
                               v-model="row.first_name"
                               :class="{'error-input': !row.first_name}"
                               class="regulatory-report-input font-weight-bold">
                      </div>
                      <div style="flex: 0 0 120px; border-right: 1px solid; padding: 2px 6px;">
                        <input type="text"
                               v-model="row.middle_name"
                               :class="{'error-input': !row.middle_name}"
                               class="regulatory-report-input font-weight-bold">
                      </div>
                      <div style="flex: 1; padding: 2px 6px;">
                        <input type="text"
                               v-model="row.last_name"
                               :class="{'error-input': !row.last_name}"
                               class="regulatory-report-input font-weight-bold">
                      </div>
                    </div>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model.number="row.without_days"
                           v-integer
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1" style="width: 100px; min-width: 100px; max-width: 100px">
                    <input type="text"
                           v-model.number="row.decree_days"
                           v-integer
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model.number="row.base"
                           v-decimal
                           @change="getTotalsD1_1"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model.number="row.result"
                           @change="getTotalsD1_1"
                           class="regulatory-report-input text-right">
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.special_experience"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.new_work_place"
                           v-integer
                           class="regulatory-report-input text-center">
                  </td>
                </tr>
              </template>
              <tr class="without-border" style="min-height: 21px; height: 21px">
                <td colspan="5" rowspan="2" class="without-border"></td>
                <td colspan="5" rowspan="2" class="with-border font-weight-bold text-right">Усього:</td>
                <td colspan="1" rowspan="1" class="text-right font-weight-bold">
                  {{ totals.table_esv_1.base }}
                </td>
                <td colspan="1" rowspan="2" class="text-right font-weight-bold">
                  {{ totals.table_esv_1.base_diff }}
                </td>
                <td colspan="1" rowspan="1" class="text-right font-weight-bold">
                  {{ totals.table_esv_1.withheld_sum }}
                </td>
                <td colspan="4" rowspan="2" class="without-border text-right">

                </td>
              </tr>
              <tr class="without-border" style="min-height: 21px; height: 21px">
                <td colspan="1" rowspan="1" class="text-right font-weight-bold">
                  {{ totals.table_esv_1.base }}
                </td>
                <td colspan="1" rowspan="1" class="text-right font-weight-bold">
                  {{ totals.table_esv_1.result }}
                </td>
              </tr>
              </tbody>
            </table>
            <div class="mt-4">
              <v-btn small depressed color="grey lighten-3" @click="add_table_1_1_row" class="mr-2">
                <v-icon left size="22">
                  mdi-plus
                </v-icon>
                Додати рядок
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item class="pa-0" style="height: calc(100vh - 158px); overflow: auto" v-if="header.pages.d4.active">
            <table class="regulatory-report-table" style="width: 1220px">
              <caption>
                <div style="font-size: 14px; line-height: 18px; padding: 8px 4px; margin-top: 10px">
                  Додаток 4 ДФ
                  до податкового розрахунку сум доходу, нарахованого (сплаченого) на користь платників
                  податків - фізичних осіб, і сум утриманого з них податку, а також сум нарахованого
                  єдиного внеску
                  (пункт 4 розділу IV )
                </div>
              </caption>
              <thead>
              <tr class="without-border">
                <th colspan="2" class="text-left">
                  Працювало за трудовими договорами (контрактами)
                </th>
                <th colspan="1">
                  {{ totals.table_pdfo_1.people_main }}
                </th>
                <th colspan="10" class="without-border"></th>
              </tr>
              <tr class="without-border">
                <th colspan="2" class="text-left">
                  Працювало за цивільно-правовими договорами
                </th>
                <th colspan="1">
                  {{ totals.table_pdfo_1.people_by_cln }}
                </th>
                <th colspan="10" class="without-border"></th>
              </tr>
              <tr class="without-border">
                <th colspan="13" class="without-border" style="height: 25px"></th>
              </tr>
              <tr>
                <th colspan="13">
                  Розділ I. Суми доходу, нарахованого (сплаченого) на користь фізичних осіб, і суми
                  утриманого з них податку
                </th>
              </tr>
              <tr>
                <th rowspan="2"
                    style="width: 60px; max-width: 60px"
                >
                  № з/п
                </th>
                <th rowspan="2">
                  Податковий номер або серія та номер паспорта*
                </th>
                <th rowspan="2" style="width: 100px; max-width: 100px">
                  Сума <br>
                  нарахова- <br>
                  ного доходу <br>
                  (грн, коп.)
                </th>
                <th rowspan="2" style="width: 100px; max-width: 100px">
                  Сума <br>
                  виплаченого <br>
                  доходу <br>
                  (грн, коп.)
                </th>
                <th rowspan="1" colspan="2">
                  Сума утриманого <br>
                  податку (грн, коп.)

                </th>
                <th rowspan="1" colspan="2">
                  Сума військового <br>
                  збору
                </th>
                <th rowspan="2" style="width: 56px; max-width: 56px">
                  Оз- <br>
                  нака <br>
                  до- <br>
                  ходу
                </th>
                <th rowspan="1" colspan="2">
                  Дата
                </th>
                <th rowspan="2" style="width: 56px; max-width: 56px">
                  Ознака <br>
                  подат. <br>
                  соц. <br>
                  пільги
                </th>
                <th rowspan="2" style="width: 56px; max-width: 56px">
                  Ознака <br>
                  (0,1)
                </th>
              </tr>
              <tr>
                <th rowspan="1" style="width: 94px; max-width: 94px">
                  нарахова- <br>
                  ного
                </th>
                <th rowspan="1" style="width: 94px; max-width: 94px">
                  перера- <br>
                  хованого
                </th>
                <th rowspan="1" style="width: 94px; max-width: 94px">
                  нарахова- <br>
                  ного
                </th>
                <th rowspan="1" style="width: 94px; max-width: 94px">
                  перера- <br>
                  хованого
                </th>
                <th rowspan="1" style="width: 100px; max-width: 100px">
                  прийняття <br>
                  на роботу <br>
                  (дд/мм/рррр)
                </th>
                <th rowspan="1" style="width: 100px; max-width: 100px">
                  звільнення <br>
                  з роботи <br>
                  (дд/мм/рррр)
                </th>
              </tr>
              <tr>
                <th>1</th>
                <th>2</th>
                <th>3а</th>
                <th>3</th>
                <th>4а</th>
                <th>4</th>
                <th>5а</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, idx) in table_pdfo_1">
                <tr :key="`pdfo-1-row-1-${row.row_num}`">
                  <td rowspan="2" class="text-right">
                    {{ idx + 1 }}
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.ipn"
                           :class="{'error-input': !row.ipn}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.charge_sum"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.charge_sum_withheld"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.pdfo_charge_sum"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.pdfo_charge_sum_withheld"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.military_charge_sum"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.military_charge_sum_withheld"
                           v-decimal
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.profit_code"
                           v-integer
                           @change="getTotalsD4_1"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <date_input small transparent
                                v-model="row.accept_date"
                                inner-style="font-size: 12px !important; padding: 0 !important; border-bottom: none; min-height: min-height: 20px;"
                                :required="false"
                                :time="false"/>
                  </td>
                  <td rowspan="2">
                    <date_input small transparent
                                v-model="row.release_date"
                                inner-style="font-size: 12px !important; padding: 0 !important; border-bottom: none; min-height: min-height: 20px;"
                                :required="false"
                                :time="false"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.privilege_code"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.sign_row"
                           v-integer
                           class="regulatory-report-input text-center"/>

                    <v-btn small icon style="margin-left: 9px" @click="delete_row_d4_1(row)">
                      <v-icon small color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr :key="`pdfo-1-row-2-${row.row_num}`">
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.full_name"
                           :class="{'error-input': !row.full_name}"
                           class="regulatory-report-input text-left"/>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="2" class="text-right font-weight-bold">Усього:</td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.charge_sum }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.charge_sum_withheld }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.pdfo_charge_sum }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.pdfo_charge_sum_withheld }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.military_charge_sum }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ totals.table_pdfo_1.military_charge_sum_withheld }}
                </td>
                <td class="text-center">X</td>
                <td class="text-center">X</td>
                <td class="text-center">X</td>
                <td class="text-center">X</td>
                <td class="text-center">X</td>
              </tr>
              <tr class="without-border" style="height: 30px">
                <td colspan="13" class="without-border"></td>
              </tr>
              <tr class="without-border">
                <td colspan="2" class="without-border pa-0">
                  <div class="d-flex">
                    <div style="flex: 0 0 50%; font-weight: bold">
                      Кількість рядків
                      (розділ І)
                    </div>
                    <div style="flex: 1; border: 1px solid grey; line-height: 30px"
                         class="text-center font-weight-bold">
                      {{ totals.table_pdfo_1.row_count }}
                    </div>
                  </div>
                </td>
                <td colspan="1" class="without-border"></td>
                <td colspan="3" class="without-border pa-0">
                  <div class="d-flex">
                    <div style="flex: 0 0 50%; font-weight: bold">
                      Кількість фіз. осіб
                      (розділ І)
                    </div>
                    <div style="flex: 1; border: 1px solid grey; line-height: 30px"
                         class="text-center font-weight-bold">
                      {{ totals.table_pdfo_1.people_count }}
                    </div>
                  </div>
                </td>
                <td colspan="1" class="without-border"></td>
                <td colspan="3" class="without-border pa-0">
                  <div class="d-flex">
                    <div style="flex: 0 0 50%; font-weight: bold">
                      Кількість <br> сторінок
                    </div>
                    <div style="flex: 1; border: 1px solid grey; line-height: 30px"
                         class="text-center font-weight-bold">
                      {{ totals.table_pdfo_1.page_count }}
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="mt-4">
              <v-btn small depressed color="grey lighten-3" @click="add_table_4_1_row" class="mr-2">
                <v-icon left size="22">
                  mdi-plus
                </v-icon>
                Додати рядок
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item class="pa-0" style="height: calc(100vh - 158px); overflow: auto" v-if="header.pages.d5.active">
            <table class="regulatory-report-table" style="width: 1300px">
              <caption>
                <div style="font-size: 14px; line-height: 18px; padding: 8px 4px; margin-top: 10px">
                  Додаток 5
                  до Податкового розрахунку сум доходу, нарахованого (сплаченого) на користь платників
                  податків - фізичних осіб, і сум утриманого з них податку, а також сум нарахованого
                  єдиного внеску (Д5)
                  (пункт 5 розділу IV )
                </div>
              </caption>
              <thead>
              <tr>
                <th rowspan="2"
                    style="width: 40px; max-width: 40px"
                >
                  № з/п
                </th>
                <th rowspan="2"
                    style="width: 40px; max-width: 40px">
                  5. Гро- <br>
                  мадя- <br>
                  нин <br>
                  Укра- <br>
                  їни
                  (1-так, <br>
                  0-ні)
                </th>
                <th rowspan="2"
                    style="width: 46px; max-width: 46px">
                  6. Дого- <br>
                  вір ЦПХ <br>
                  за осно- <br>
                  вним <br>
                  місцем- <br>
                  роботи <br>
                  або за суміс- <br>
                  ниц- <br>
                  твом <br>
                  (1 - так, <br>
                  0 - ні)
                </th>
                <th rowspan="2"
                    style="width: 45px; max-width: 45px">
                  7.Кате- <br>
                  горія <br>
                  особи <br>
                  **
                </th>
                <th rowspan="2"
                    style="width: 100px; max-width: 100px"
                >
                  8. Реєстраційний <br>
                  номер облікової <br>
                  картки платника <br>
                  податків або серія <br>
                  (за наявності) <br>
                  та номер паспорта <br>
                  за формою БКNNXXXXXX <br>
                  /ПXXXXXXXXX застрахо- <br>
                  ваної особи*
                </th>
                <th rowspan="2" colspan="3">
                  9. Прізвище, ім'я, по батькові (за <br>
                  наявності) застрахованої особи
                </th>
                <th rowspan="1" colspan="2" style="width: 200px; max-width: 200px">
                  10. Період трудових або цивільно-правових відносин, проходження військової служби та
                  відпусток
                </th>
                <th rowspan="1" colspan="1" style="width: 60px; max-width: 60px">
                  11. Внутрі- <br>
                  шній суміс- <br>
                  ник (1 - так,
                  0 - ні)
                </th>
                <th rowspan="1" colspan="1" style="width: 90px; max-width: 90px">
                  13. Професійна назва роботи
                </th>
                <th rowspan="1" colspan="1" style="width: 60px; max-width: 60px">
                  14. Код
                  класифі- <br>
                  катора <br>
                  професій
                </th>
                <th rowspan="1" colspan="1" style="width: 110px; max-width: 110px">
                  16. Документ підстава про початок, кінець трудових
                  або цивільно - правових відносин, переведення
                  на іншу посаду, роботи та відпусток
                </th>
                <th rowspan="1" colspan="1" style="width: 100px; max-width: 100px">
                  18. Дата створення
                  нового робочого
                  місця (штатної
                  одиниці), на яке у
                  звітному періоді
                  працевлашто- <br>
                  ваний працівник
                </th>
                <th rowspan="2" colspan="1" style="width: 62px; max-width: 62px">
                  20. ознака "0", якщо рядок потрібно ввести, чи ознака "1", якщо рядок потрібно
                  виключити. тільки для "Звітного нового" та "Уточнюю- <br>
                  чого"
                </th>
              </tr>
              <tr>
                <th rowspan="1" colspan="1" style="width: 100px; max-width: 100px">
                  дата початку (за формою ДДММРРРР)
                </th>
                <th rowspan="1" colspan="1" style="width: 100px; max-width: 100px">
                  дата закінчення (за формою ДДММРРРР)
                </th>
                <th rowspan="1" colspan="1" style="width: 60px; max-width: 60px">
                  12. Переве- <br>
                  дено, при- <br>
                  значено на іншу посаду або роботу, перемі- <br>
                  щено до іншого
                  підроз- <br>
                  ділу
                  (1 - так, 0 - ні)
                </th>
                <th rowspan="1" colspan="2" style="width: 190px; max-width: 190px">
                  15. Посада
                </th>
                <th rowspan="1" colspan="1" style="width: 110px; max-width: 110px">
                  17. Підстава для
                  припинення трудових або
                  цивільно – правових
                  відносин
                </th>
                <th rowspan="1" colspan="1" style="width: 100px; max-width: 100px">
                  19. Військове
                  звання
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, idx) in table_hire_release">
                <tr :key="`hire_release-row-1-${row.row_num}`">
                  <td rowspan="2" class="text-right">
                    {{ idx + 1 }}
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.national"
                           v-integer
                           :class="{'error-input': row.national === null || row.national === ''}"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.contract_cln"
                           v-integer
                           :class="{'error-input': row.contract_cln === null || row.contract_cln === ''}"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model.number="row.person_category"
                           v-integer
                           :class="{'error-input': row.person_category === null || row.person_category === ''}"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.ipn"
                           :class="{'error-input': !row.ipn}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.first_name"
                           :class="{'error-input': !row.first_name}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.middle_name"
                           :class="{'error-input': !row.middle_name}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.last_name"
                           :class="{'error-input': !row.last_name}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="2">
                    <date_input small transparent
                                v-model="row.date_start"
                                inner-style="font-size: 12px !important; padding: 0 !important; border-bottom: none; min-height: min-height: 20px;"
                                :required="false"
                                :time="false"/>
                  </td>
                  <td rowspan="2">
                    <date_input small transparent
                                v-model="row.date_end"
                                inner-style="font-size: 12px !important; padding: 0 !important; border-bottom: none; min-height: min-height: 20px;"
                                :required="false"
                                :time="false"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model.number="row.inner_work"
                           v-integer
                           :class="{'error-input': row.inner_work === null || row.inner_work === ''}"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.position_name_kp"
                           :class="{'error-input': !row.position_name_kp}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.position_code_kp"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.document_hire_reason"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="1">
                    <date_input small transparent
                                v-model="row.new_work_place_date"
                                inner-style="font-size: 12px !important; padding: 0 !important; border-bottom: none; min-height: min-height: 20px;"
                                :required="false"
                                :time="false"/>
                  </td>
                  <td rowspan="2">
                    <input type="text"
                           v-model="row.row_sign"
                           v-integer
                           class="regulatory-report-input text-left"/>

                    <v-btn small icon style="margin-left: 9px" @click="delete_row_d5(row)">
                      <v-icon small color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr :key="`hire_release-row-2-${row.row_num}`">
                  <td rowspan="1">
                    <input type="text"
                           v-model.number="row.its_work_transfer"
                           v-integer
                           :class="{'error-input': row.its_work_transfer === null || row.its_work_transfer === ''}"
                           class="regulatory-report-input text-center"/>
                  </td>
                  <td rowspan="1" colspan="2">
                    <input type="text"
                           v-model="row.position_name"
                           :class="{'error-input': !row.position_name}"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.document_release_reason"
                           class="regulatory-report-input text-left"/>
                  </td>
                  <td rowspan="1">
                    <input type="text"
                           v-model="row.millitary_position_name"
                           class="regulatory-report-input text-left"/>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <div class="mt-4">
              <v-btn small depressed color="grey lighten-3" @click="add_table_5_row" class="mr-2">
                <v-icon left size="22">
                  mdi-plus
                </v-icon>
                Додати рядок
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import regulatoryAPI from "@/utils/axios/accounting/regulatory_reports"
import {endOfMonth, getMonthFromQuarter} from "@/utils/icons"
import {round_number} from "@/utils/icons"
import {GET_ORGANIZATION_PRINT_DATA} from "@/store/actions/organization";
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import reportAPI from "@/utils/axios/reports";
import {maxBy} from "lodash";
import {QUESTION_SHOW} from "@/store/actions/question";

export default {
  name: 'esv_union_quarter_j0500110',
  props: {
    report_id: {
      type: Number,
      default: null
    },
    report_is_new: {
      type: Boolean,
      default: false
    },
    report_type: {
      type: String,
      default: ''
    }
  },
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    PrintReport: () => import("@/components/PrintReport")
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      modalAnswer: 'question_answer'
    }),
  },
  data() {
    return {
      modal_answer_watcher: null,
      formValid: false,
      report_dialog: {
        dialog: false,
        component_name: '',
        report_title: '',
        accounting_id: null
      },
      months: [
        {text: 'Січень', value: 1},
        {text: 'Лютий', value: 2},
        {text: 'Березень', value: 3},
        {text: 'Квітень', value: 4},
        {text: 'Травень', value: 5},
        {text: 'Червень', value: 6},
        {text: 'Липень', value: 7},
        {text: 'Серпень', value: 8},
        {text: 'Вересень', value: 9},
        {text: 'Жовтень', value: 10},
        {text: 'Листопад', value: 11},
        {text: 'Грудень', value: 12},
      ],
      report_types: [
        {text: 'Звітний', value: 'reportable'},
        {text: 'Звітний новий', value: 'reportable_new'},
        {text: 'Уточнюючий', value: 'clarifying'},
        {text: 'Довідковий', value: 'reference'},
      ],
      header: {
        report_id: null,
        report_type: null,
        quarter: null,
        month: null,
        year: null,
        date_start: null,
        date_end: null,
        number: null,
        payer_name: null,
        payer_ipn_or_passport: null,
        payer_city_code: null,
        payer_zip_code: null,
        payer_postal_address: null,
        payer_phone: null,
        payer_fax: null,
        payer_email: null,
        send_date: null,
        controlling_body: null,
        director_ipn: null,
        director_name: null,
        accountant_ipn: null,
        accountant_name: null,
        pages: {
          d1: {title: 'Додаток 1', active: true},
          d4: {title: 'Додаток 4 ДФ', active: true},
          d5: {title: 'Додаток 5', active: true},
        },
      },
      totals: {
        table_esv_1: {
          base: 0,
          base_diff: 0,
          withheld_sum: 0,
          result: 0,
        },
        table_esv_2: {
          base: 0,
          base_diff: 0,
          withheld_sum: 0,
          result: 0,
        },
        table_esv_3: {
          base: 0,
          base_diff: 0,
          withheld_sum: 0,
          result: 0,
        },
        table_pdfo_1: {
          charge_sum: 0,
          charge_sum_withheld: 0,
          pdfo_charge_sum: 0,
          pdfo_charge_sum_withheld: 0,
          military_charge_sum: 0,
          military_charge_sum_withheld: 0,
          row_count: 0,
          people_count: 0,
          page_count: 0,
          people_main: 0,
          people_by_cln: 0
        },
        table_pdfo_2: {
          charge_sum: 0,
          charge_sum_withheld: 0,
          pdfo_charge_sum: 0,
          pdfo_charge_sum_withheld: 0,
          military_charge_sum: 0,
          military_charge_sum_withheld: 0,
          row_count: 0,
          people_count: 0,
          page_count: 0,
          people_main: 0,
          people_by_cln: 0
        },
        table_pdfo_3: {
          charge_sum: 0,
          charge_sum_withheld: 0,
          pdfo_charge_sum: 0,
          pdfo_charge_sum_withheld: 0,
          military_charge_sum: 0,
          military_charge_sum_withheld: 0,
          row_count: 0,
          people_count: 0,
          page_count: 0,
          people_main: 0,
          people_by_cln: 0
        },
        declaration: {
          d1: {active: false, count: 0, pages: 0},
          d4: {active: false, count: 0, pages: 0},
          d5: {active: false, count: 0, pages: 0},
        }
      },
      declaration: {
        table_main_1: {},
        table_main_2: {},
        table_main_3: {},
      },
      table_esv_1: [],
      table_esv_2: [],
      table_esv_3: [],
      table_pdfo_1: [],
      table_pdfo_2: [],
      table_pdfo_3: [],
      table_hire_release: [],
      print_data: {},
      report_state: {
        show: false,
        errors: []
      }
    }
  },
  methods: {
    openReportDialog(component_name, report_title) {
      if (!component_name) {
        this.report_dialog.dialog = false
        this.report_dialog.component_name = ''
        this.report_dialog.report_title = ''
        this.report_dialog.accounting_id = null
      }
      this.report_dialog.component_name = component_name
      this.report_dialog.report_title = report_title
      this.report_dialog.dialog = true
      this.report_dialog.accounting_id = null
    },
    closeReportDialog() {
      this.report_dialog.dialog = false
      this.report_dialog.component_name = ''
      this.report_dialog.report_title = ''
      this.report_dialog.accounting_id = null
    },
    save_report() {
      const payload = {
        report_type: 'esv_declaration',
        report_inner_type: this.header.report_type,
        period_type: 'month',
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'esv_union_quarter_j0500110'
      }

      payload.report_data = {
        header: this.header,
        declaration: this.declaration,
        table_esv_1: this.table_esv_1,
        table_esv_2: this.table_esv_2,
        table_esv_3: this.table_esv_3,
        table_pdfo_1: this.table_pdfo_1,
        table_pdfo_2: this.table_pdfo_2,
        table_pdfo_3: this.table_pdfo_3,
        table_hire_release: this.table_hire_release,
        totals: this.totals
      }

      if (this.report_is_new) {
        regulatoryAPI.create_report(payload)
            .then(response => response.data)
            .then(data => {
              this.header.report_id = data.id
              this.$store.commit(ALERT_SHOW, {text: 'Звіт успішно створено', color: 'success'})
              this.$emit('reportSaved', data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        payload.id = this.report_id
        regulatoryAPI.update_report(payload)
            .then(response => response.data)
            .then(data => {
              this.header.report_id = data.id
              this.$store.commit(ALERT_SHOW, {text: 'Звіт успішно оновлено', color: 'success'})
              this.$emit('reportSaved', data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    save_data_after_fetch(data, generate = true) {
      this.table_esv_1 = data.table_esv_1.map((i, idx) => {
        return Object.assign({}, i, {row_num: idx + 1})
      })

      this.table_pdfo_1 = data.table_pdfo_1.map((i, idx) => {
        return Object.assign({}, i, {row_num: idx + 1})
      })

      this.table_hire_release = data.table_hire_release.map((i, idx) => {
        return Object.assign({}, i, {row_num: idx + 1})
      })
      this.totals = data.totals

      if (generate) {
        const main_data = Object.assign({}, data.main)
        main_data.table_main_2 = Object.assign({}, data.table_main_1)
        main_data.table_main_3 = Object.assign({}, data.table_main_1)

        Object.keys(main_data.table_main_2).forEach(key => {
          main_data.table_main_2[key] = 0
        })
        Object.keys(main_data.table_main_3).forEach(key => {
          main_data.table_main_3[key] = 0
        })

        this.declaration = main_data
      } else {
        this.declaration = data.declaration
      }

      if (this.header.pages.d1.active) {
        this.totals.declaration.d1.active = true
      }
      if (this.header.pages.d4.active) {
        this.totals.declaration.d4.active = true
      }
      if (this.header.pages.d5.active) {
        this.totals.declaration.d5.active = true
      }
      if (!generate) {
        this.header = data.header
      }

      this.header.report_id = this.report_id
    },
    export_report_to_xml() {
      const payload = {
        report_type: 'esv_declaration',
        report_inner_type: this.header.report_type,
        period_type: 'month',
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'esv_union_quarter_j0500110'
      }

      payload.report_data = {
        header: this.header,
        declaration: this.declaration,
        table_esv_1: this.table_esv_1,
        table_esv_2: this.table_esv_2,
        table_esv_3: this.table_esv_3,
        table_pdfo_1: this.table_pdfo_1,
        table_pdfo_2: this.table_pdfo_2,
        table_pdfo_3: this.table_pdfo_3,
        table_hire_release: this.table_hire_release,
        totals: this.totals,
      }

      payload.report_data.header.report_id = this.report_id

      const report_not_ready = this.report_not_ready(true)

      if (!report_not_ready) {
        regulatoryAPI.save_report_to_xml(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = response.headers['x-blob-file-name'] || 'generated.zip'
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })

      }
    },
    report_not_ready(show_errors = false) {
      const errors = {
        header: [],
        declaration: [],
        d1_1: [],
        d1_2: [],
        d1_3: [],
        d4_1: [],
        d4_2: [],
        d4_3: [],
        d5: [],
      }
      if (!this.header.report_type) {
        errors.header.push('[Налаштування звіту] Не заповнений тип звіту')
      }
      if (!this.header.year) {
        errors.header.push('[Налаштування звіту] Не заповнений рік звіту')
      }
      if (!this.header.month) {
        errors.header.push('[Налаштування звіту] Не заповнений місяць звіту')
      }
      if (!this.header.payer_name) {
        errors.header.push('[Налаштування звіту] Не заповнена назва платника')
      }
      if (!this.header.payer_ipn_or_passport) {
        errors.header.push('[Налаштування звіту] Не заповнений податковий номер та/або серія та номер паспорта')
      }
      if (!this.header.payer_city_code) {
        errors.header.push('[Налаштування звіту] Не заповнена код КОТАТТГ')
      }
      if (!this.header.payer_postal_address) {
        errors.header.push('[Налаштування звіту] Не заповнена податкова адреса')
      }
      if (!this.header.payer_zip_code) {
        errors.header.push('[Налаштування звіту] Не заповнена поштовий індекс')
      }
      if (!this.header.controlling_body) {
        errors.header.push('[Налаштування звіту] Не заповнена назва контролюючого органу')
      }
      if (!this.header.director_ipn) {
        errors.header.push('[Налаштування звіту] Не заповнений ІПН керівника')
      }
      if (!this.header.director_name) {
        errors.header.push('[Налаштування звіту] Не заповнено ПІБ керівника')
      }
      if (this.declaration.table_main_1.total_row_7 !== this.totals.table_esv_1.result) {
        errors.declaration.push('[Декларація] Код рядка 3 не співпадає із підсумком нарахованого ЄСВ із таблиці Д1 (1)')
      }
      if (this.declaration.table_main_1.total_row_1 !== this.totals.table_esv_1.base) {
        errors.declaration.push('[Декларація] Код рядка 1 не співпадає із підсумком суми нарах. заробіної плати із таблиці Д1 (1)')
      }

      this.table_esv_1.forEach(item => {
        if (item.national === '' || item.national === null) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнене громадянство [5.]`)
        }
        if (!item.gender) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнена стать [6.]`)
        }
        if (!item.ipn) {
          errors.d1_1.push(`(Рядок №${item.row_num}) Не заповнений іпн або серія та номер паспорта [7.]`)
        }
        if (!item.category_code) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнений код категорії ЗО [8.]`)
        }
        if (!item.month_number) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнений місяць [10.]`)
        }
        if (!item.year_number) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнений рік [10.]`)
        }
        if (!item.first_name) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнене прізвище [11.]`)
        }
        if (!item.middle_name) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнене ім’я [11.]`)
        }
        if (!item.last_name) {
          errors.d1_1.push(`[Д1(1)](Рядок №${item.row_num}) Не заповнене по батькові [11.]`)
        }
      })

      this.table_pdfo_1.forEach(item => {
        if (!item.ipn) {
          errors.d4_1.push(`[Д4(1)](Рядок №${item.row_num}) Не заповнений іпн або серія та номер паспорта [2]`)
        }
        if (!item.profit_code) {
          errors.d4_1.push(`[Д4(1)](Рядок №${item.row_num}) Не заповнений код доходу ЗО [6]`)
        }
      })

      this.table_hire_release.forEach(item => {
        if (item.national === '' || item.national === null) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнене громадянство [5.]`)
        }
        if (item.contract_cln === '' || item.contract_cln === null) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнена ознака договору ЦПХ [6.]`)
        }
        if (item.person_category === '' || item.person_category === null) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнена категорія особи [7.]`)
        }
        if (!item.ipn) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнений іпн або серія та номер паспорта [8.]`)
        }
        if (!item.first_name) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнене прізвище [9.]`)
        }
        if (!item.middle_name) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнене ім’я [9.]`)
        }
        if (!item.last_name) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнене по батькові [9.]`)
        }
        if (!item.date_start && !item.date_end) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Дата початку або дата закінчення маають бути заповнені [10.]`)
        }

        if (item.inner_work === '' || item.inner_work === null) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнена ознака внутр. сумісн [11.]`)
        }

        if (item.its_work_transfer === '' || item.its_work_transfer === null) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнена ознака переведення [12.]`)
        }

        if (!item.position_name_kp) {
          errors.d5.push(`[Д5](Рядок №${item.row_num}) Не заповнена професійна назва роботи [13.]`)
        }
      })

      const regexp = /(?<table_name>^\[.*?\])?(?<row_number>\(.*?\))?(?<error_text>.*)/

      const error_count = errors.header.length + errors.declaration.length + errors.d1_1.length
          + errors.d4_1.length + errors.d5.length

      this.report_state.errors = []
      if (show_errors && error_count > 0) {
        this.report_state.errors = errors.header.concat(errors.declaration,
            errors.d1_1, errors.d4_1, errors.d5).map(item => {
              const groups = item.match(regexp).groups
              let {table_name, row_number, error_text} = groups
              table_name = (table_name || '').replace('[', '')
              table_name = table_name.replace(']', '').trim()
              row_number = (row_number || '').replace('(', '')
              row_number = row_number.replace(')', '')
              row_number = row_number.replace('Рядок №', '').trim()
              error_text = error_text.trim()

            return {table_name, row_number, error_text}
        })
        this.report_state.show = true
      }

      return error_count > 0
    },
    calcMainPagesCount() {
      this.totals.declaration.d1.active = false
      this.totals.declaration.d1.count = 0
      this.totals.declaration.d1.pages = 0

      this.totals.declaration.d4.active = false
      this.totals.declaration.d4.count = 0
      this.totals.declaration.d4.pages = 0

      this.totals.declaration.d5.active = false
      this.totals.declaration.d5.count = 0
      this.totals.declaration.d5.pages = 0

      if (this.header.pages.d1.active) {
        this.totals.declaration.d1.active = true
        this.totals.declaration.d1.count = 3
        this.totals.declaration.d1.pages = this.table_esv_1.length <= 1 ? 1 : 2
      }

      if (this.header.pages.d4.active) {
        this.totals.declaration.d4.active = true
        this.totals.declaration.d4.count = 3
        this.totals.declaration.d4.pages = this.table_pdfo_1.length <= 1 ? 1 : 2
      }

      if (this.header.pages.d5.active) {
        this.totals.declaration.d5.active = true
        this.totals.declaration.d5.count = 1
        this.totals.declaration.d5.pages = this.table_hire_release.length <= 1 ? 1 : 2
      }
    },
    getMainPagesCount() {
      this.$nextTick(() => {
        this.calcMainPagesCount()
      })
    },
    getMonthFromQuarter,
    getFullMonth(month) {
      if (!month) return 1
      return month < 10 ? `0${month}` : `${month}`
    },
    onDateChange() {
      this.$nextTick(() => {
        if (this.header.month && this.header.year) {
          const date_start = `${this.header.year}-${this.getFullMonth(this.header.month)}-01`
          const date_end = endOfMonth(date_start, 'string')
          this.header.date_start = date_start
          this.header.date_end = date_end
        } else {
          this.header.date_start = null
          this.header.date_end = null
        }
      })
    },
    onReportTypeChange() {
      this.$nextTick(() => {
        if (this.header.report_type === 'reference') {
          this.header.pages.d4.active = false
        } else {
          this.header.pages.d4.active = true
        }
        this.getMainPagesCount()
      })
    },
    generate_report() {
      const payload = {
        report_type: 'esv_declaration',
        report_inner_type: this.header.report_type,
        period_type: 'month',
        date_start: this.header.date_start,
        date_end: this.header.date_end,
        number: this.header.number,
        report_form: 'esv_union_quarter_j0500110'
      }

      if (!this.header.report_type) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть тип звіту', color: 'error'})
        return
      }

      if (!this.header.month) {
        this.$store.commit(ALERT_SHOW, {text: 'Оберіть необхідний місяць', color: 'error'})
        return
      }

      regulatoryAPI.generate_report(payload)
          .then(response => response.data)
          .then(data => {
            this.save_data_after_fetch(data)

            this.$store.dispatch(GET_ORGANIZATION_PRINT_DATA)
                .then(
                    data => {
                      this.print_data = data
                      this.header.payer_name = this.organization.full_name
                      this.header.payer_postal_address = this.organization.address_yur
                      this.header.payer_ipn_or_passport = this.organization.code

                      const director = (data.response_people || []).find(i => i.category === 'director')
                      const chief_accountant = (data.response_people || []).find(i => i.category === "chief_accountant")

                      if (director) {
                        this.header.director_name = director.full_name
                        this.header.director_ipn = director.ipn
                      } else {
                        this.header.director_name = ""
                        this.header.director_ipn = ""
                      }

                      if (chief_accountant) {
                        this.header.accountant_name = chief_accountant.full_name
                        this.header.accountant_ipn = chief_accountant.ipn
                      } else {
                        this.header.accountant_name = ""
                        this.header.accountant_ipn = ""
                      }

                      const report_not_ready = this.report_not_ready(true)

                      if (!report_not_ready) {
                        reportAPI.report_settings("regular_report_esv_declaration")
                            .then(response => response.data)
                            .then(data => {
                              const server_filters = JSON.parse(data)
                              Object.keys(this.header).forEach(key => {
                                if (server_filters[key] !== undefined) {
                                  this.header[key] = server_filters[key]
                                }
                              })
                            })
                      }
                    }
                )
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    calcTotalsMain() {
      const tables = [1, 2, 3]

      tables.forEach(table_number => {
        const table_name = `table_main_${table_number}`
        this.declaration[table_name].total_row_1 = round_number(
            ((this.declaration[table_name].salary_charge || 0) +
                (this.declaration[table_name].contract_by_cln || 0) +
                (this.declaration[table_name].hospital_by_organization || 0) +
                (this.declaration[table_name].hospital_by_social || 0) +
                (this.declaration[table_name].military_5_3 || 0) +
                (this.declaration[table_name].military_5_5 || 0) +
                (this.declaration[table_name].military_22 || 0)), 2)

        this.declaration[table_name].total_row_1_millitary = round_number(
            ((this.declaration[table_name].military_5_3 || 0) +
                (this.declaration[table_name].military_5_5 || 0) +
                (this.declaration[table_name].military_22 || 0)), 2)

        this.declaration[table_name].total_row_2 = round_number(
            ((this.declaration[table_name].max_salary_charge_22 || 0) +
                (this.declaration[table_name].max_salary_charge_841 || 0) +
                (this.declaration[table_name].max_salary_charge_5_3 || 0) +
                (this.declaration[table_name].max_salary_charge_5_5 || 0) +
                (this.declaration[table_name].max_salary_add_22 || 0) +
                (this.declaration[table_name].max_salary_millitary_5_3 || 0) +
                (this.declaration[table_name].max_salary_millitary_5_5 || 0) +
                (this.declaration[table_name].max_salary_millitary_22 || 0)
            ), 2)

        this.declaration[table_name].total_row_2_millitary = round_number(
            ((this.declaration[table_name].max_salary_millitary_5_3 || 0) +
                (this.declaration[table_name].max_salary_millitary_5_5 || 0) +
                (this.declaration[table_name].max_salary_millitary_22 || 0)), 2)

        this.declaration[table_name].total_row_3 = round_number(
            ((this.declaration[table_name].esv_main_22 || 0) +
                (this.declaration[table_name].esv_main_841 || 0) +
                (this.declaration[table_name].esv_5_3 || 0) +
                (this.declaration[table_name].esv_5_5 || 0) +
                (this.declaration[table_name].esv_5_5 || 0) +
                (this.declaration[table_name].esv_public_invalid_841 || 0) +
                (this.declaration[table_name].esv_diff_22 || 0) +
                (this.declaration[table_name].esv_diplomat_22 || 0) +
                (this.declaration[table_name].esv_millitary_5_3 || 0) +
                (this.declaration[table_name].esv_millitary_5_5 || 0) +
                (this.declaration[table_name].esv_millitary_22 || 0)
            ), 2)

        this.declaration[table_name].total_row_3_millitary = round_number(
            ((this.declaration[table_name].esv_millitary_5_3 || 0) +
                (this.declaration[table_name].esv_millitary_5_5 || 0) +
                (this.declaration[table_name].esv_millitary_22 || 0)), 2)

        this.declaration[table_name].total_row_4 = round_number(
            ((this.declaration[table_name].esv_dorah_841 || 0) +
                (this.declaration[table_name].esv_dorah_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_5_5 || 0) +
                (this.declaration[table_name].esv_dorah_class_rise || 0) +
                (this.declaration[table_name].esv_dorah_withdrow || 0) +
                (this.declaration[table_name].esv_dorah_millitary_53 || 0) +
                (this.declaration[table_name].esv_dorah_millitary_55 || 0) +
                (this.declaration[table_name].esv_dorah_millitary_22 || 0)
            ), 2)

        this.declaration[table_name].total_row_4_1 = round_number(
            ((this.declaration[table_name].esv_dorah_841 || 0) +
                (this.declaration[table_name].esv_dorah_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_5_5 || 0)), 2)

        this.declaration[table_name].total_row_4_millitary = round_number(
            ((this.declaration[table_name].esv_dorah_millitary_53 || 0) +
                (this.declaration[table_name].esv_dorah_millitary_55 || 0) +
                (this.declaration[table_name].esv_dorah_millitary_22 || 0)), 2)

        this.declaration[table_name].total_row_6 = round_number(
            ((this.declaration[table_name].esv_dorah_decrease_841 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_5_5 || 0) +
                (this.declaration[table_name].esv_dorah_class_decrease || 0) +
                (this.declaration[table_name].esv_dorah_decrease_withdrow || 0) +
                (this.declaration[table_name].esv_dorah_decrease_millitary_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_millitary_5_5 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_millitary_22 || 0)
            ), 2)

        this.declaration[table_name].total_row_6_1 = round_number(
            ((this.declaration[table_name].esv_dorah_decrease_841 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_5_5 || 0)), 2)

        this.declaration[table_name].total_row_6_millitary = round_number(
            ((this.declaration[table_name].esv_dorah_decrease_millitary_5_3 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_millitary_5_5 || 0) +
                (this.declaration[table_name].esv_dorah_decrease_millitary_22 || 0)), 2)

        this.declaration[table_name].total_row_7 = round_number(
            ((this.declaration[table_name].total_row_3 || 0) +
                (this.declaration[table_name].total_row_4 || 0) -
                (this.declaration[table_name].total_row_6 || 0)), 2)
      })

      this.declaration.table_main_1.total_row_8 = round_number((
          (this.declaration.table_main_1.total_row_7 || 0) +
          (this.declaration.table_main_2.total_row_7 || 0) +
          (this.declaration.table_main_3.total_row_7 || 0)
      ), 2)

      this.declaration.table_main_1.total_row_5 = round_number((
          (this.declaration.table_main_1.esv_penya || 0) +
          (this.declaration.table_main_2.esv_penya || 0) +
          (this.declaration.table_main_3.esv_penya || 0)
      ), 2)
    },
    getTotalsMain() {
      this.$nextTick(() => {
        this.calcTotalsMain()
      })
    },
    calcTotalsD1_1() {
      this.totals.table_esv_1 = this.table_esv_1.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.base_diff += item.base_diff || 0
        acc.withheld_sum += item.withheld_sum || 0
        acc.result += item.result || 0
        return acc
      }, {base: 0, base_diff: 0, withheld_sum: 0, result: 0,})

      this.totals.table_esv_1.base = round_number(this.totals.table_esv_1.base, 2)
      this.totals.table_esv_1.base_diff = round_number(this.totals.table_esv_1.base_diff, 2)
      this.totals.table_esv_1.withheld_sum = round_number(this.totals.table_esv_1.withheld_sum, 2)
      this.totals.table_esv_1.result = round_number(this.totals.table_esv_1.result, 2)
    },
    calcTotalsD1_2() {
      this.totals.table_esv_2 = this.table_esv_2.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.base_diff += item.base_diff || 0
        acc.withheld_sum += item.withheld_sum || 0
        acc.result += item.result || 0
        return acc
      }, {base: 0, base_diff: 0, withheld_sum: 0, result: 0,})

      this.totals.table_esv_2.base = round_number(this.totals.table_esv_2.base, 2)
      this.totals.table_esv_2.base_diff = round_number(this.totals.table_esv_2.base_diff, 2)
      this.totals.table_esv_2.withheld_sum = round_number(this.totals.table_esv_2.withheld_sum, 2)
      this.totals.table_esv_2.result = round_number(this.totals.table_esv_2.result, 2)
    },
    calcTotalsD1_3() {
      this.totals.table_esv_3 = this.table_esv_3.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.base_diff += item.base_diff || 0
        acc.withheld_sum += item.withheld_sum || 0
        acc.result += item.result || 0
        return acc
      }, {base: 0, base_diff: 0, withheld_sum: 0, result: 0,})

      this.totals.table_esv_3.base = round_number(this.totals.table_esv_3.base, 2)
      this.totals.table_esv_3.base_diff = round_number(this.totals.table_esv_3.base_diff, 2)
      this.totals.table_esv_3.withheld_sum = round_number(this.totals.table_esv_3.withheld_sum, 2)
      this.totals.table_esv_3.result = round_number(this.totals.table_esv_3.result, 2)
    },
    calcTotalsD4_1() {
      const people_set = new Set()
      const people_main_set = new Set()
      const people_by_cln_set = new Set()

      this.totals.table_pdfo_1 = this.table_pdfo_1.reduce((acc, item) => {
            acc.charge_sum += item.charge_sum || 0
            acc.charge_sum_withheld += item.charge_sum_withheld || 0
            acc.pdfo_charge_sum += item.pdfo_charge_sum || 0
            acc.pdfo_charge_sum_withheld += item.pdfo_charge_sum_withheld || 0
            acc.military_charge_sum += item.military_charge_sum || 0
            acc.military_charge_sum_withheld += item.military_charge_sum_withheld || 0
            acc.row_count += 1

            if (item.profit_code !== '157') {
              people_set.add(item.ipn)
            }

            if (item.profit_code === '101') {
              people_main_set.add(item.ipn)
            }

            if (item.profit_code === '102') {
              people_by_cln_set.add(item.ipn)
            }

            return acc
          }, {
            charge_sum: 0,
            charge_sum_withheld: 0,
            pdfo_charge_sum: 0,
            pdfo_charge_sum_withheld: 0,
            military_charge_sum: 0,
            military_charge_sum_withheld: 0,
            row_count: 0,
            people_count: 0,
            page_count: 0,
            people_main: 0,
            people_by_cln: 0
          }
      )

      this.totals.table_pdfo_1.people_count = people_set.size
      this.totals.table_pdfo_1.people_main = people_main_set.size
      this.totals.table_pdfo_1.people_by_cln = people_by_cln_set.size
      this.totals.table_pdfo_1.charge_sum = round_number(this.totals.table_pdfo_1.charge_sum, 2)
      this.totals.table_pdfo_1.charge_sum_withheld = round_number(this.totals.table_pdfo_1.charge_sum_withheld, 2)
      this.totals.table_pdfo_1.pdfo_charge_sum = round_number(this.totals.table_pdfo_1.pdfo_charge_sum, 2)
      this.totals.table_pdfo_1.pdfo_charge_sum_withheld = round_number(this.totals.table_pdfo_1.pdfo_charge_sum_withheld, 2)
      this.totals.table_pdfo_1.military_charge_sum = round_number(this.totals.table_pdfo_1.military_charge_sum, 2)
      this.totals.table_pdfo_1.military_charge_sum_withheld = round_number(this.totals.table_pdfo_1.military_charge_sum_withheld, 2)
    },
    calcTotalsD4_2() {
      const people_set = new Set()
      const people_main_set = new Set()
      const people_by_cln_set = new Set()

      this.totals.table_pdfo_2 = this.table_pdfo_2.reduce((acc, item) => {
            acc.charge_sum += item.charge_sum || 0
            acc.charge_sum_withheld += item.charge_sum_withheld || 0
            acc.pdfo_charge_sum += item.pdfo_charge_sum || 0
            acc.pdfo_charge_sum_withheld += item.pdfo_charge_sum_withheld || 0
            acc.military_charge_sum += item.military_charge_sum || 0
            acc.military_charge_sum_withheld += item.military_charge_sum_withheld || 0
            acc.row_count += 1

            if (item.profit_code !== '157') {
              people_set.add(item.ipn)
            }

            if (item.profit_code === '101') {
              people_main_set.add(item.ipn)
            }

            if (item.profit_code === '102') {
              people_by_cln_set.add(item.ipn)
            }

            return acc
          }, {
            charge_sum: 0,
            charge_sum_withheld: 0,
            pdfo_charge_sum: 0,
            pdfo_charge_sum_withheld: 0,
            military_charge_sum: 0,
            military_charge_sum_withheld: 0,
            row_count: 0,
            people_count: 0,
            page_count: 0,
            people_main: 0,
            people_by_cln: 0
          }
      )

      this.totals.table_pdfo_2.people_count = people_set.size
      this.totals.table_pdfo_2.people_main = people_main_set.size
      this.totals.table_pdfo_2.people_by_cln = people_by_cln_set.size
      this.totals.table_pdfo_2.charge_sum = round_number(this.totals.table_pdfo_2.charge_sum, 2)
      this.totals.table_pdfo_2.charge_sum_withheld = round_number(this.totals.table_pdfo_2.charge_sum_withheld, 2)
      this.totals.table_pdfo_2.pdfo_charge_sum = round_number(this.totals.table_pdfo_2.pdfo_charge_sum, 2)
      this.totals.table_pdfo_2.pdfo_charge_sum_withheld = round_number(this.totals.table_pdfo_2.pdfo_charge_sum_withheld, 2)
      this.totals.table_pdfo_2.military_charge_sum = round_number(this.totals.table_pdfo_2.military_charge_sum, 2)
      this.totals.table_pdfo_2.military_charge_sum_withheld = round_number(this.totals.table_pdfo_2.military_charge_sum_withheld, 2)
    },
    calcTotalsD4_3() {
      const people_set = new Set()
      const people_main_set = new Set()
      const people_by_cln_set = new Set()

      this.totals.table_pdfo_3 = this.table_pdfo_3.reduce((acc, item) => {
            acc.charge_sum += item.charge_sum || 0
            acc.charge_sum_withheld += item.charge_sum_withheld || 0
            acc.pdfo_charge_sum += item.pdfo_charge_sum || 0
            acc.pdfo_charge_sum_withheld += item.pdfo_charge_sum_withheld || 0
            acc.military_charge_sum += item.military_charge_sum || 0
            acc.military_charge_sum_withheld += item.military_charge_sum_withheld || 0
            acc.row_count += 1

            if (item.profit_code !== '157') {
              people_set.add(item.ipn)
            }

            if (item.profit_code === '101') {
              people_main_set.add(item.ipn)
            }

            if (item.profit_code === '102') {
              people_by_cln_set.add(item.ipn)
            }

            return acc
          }, {
            charge_sum: 0,
            charge_sum_withheld: 0,
            pdfo_charge_sum: 0,
            pdfo_charge_sum_withheld: 0,
            military_charge_sum: 0,
            military_charge_sum_withheld: 0,
            row_count: 0,
            people_count: 0,
            page_count: 0,
            people_main: 0,
            people_by_cln: 0
          }
      )

      this.totals.table_pdfo_3.people_count = people_set.size
      this.totals.table_pdfo_3.people_main = people_main_set.size
      this.totals.table_pdfo_3.people_by_cln = people_by_cln_set.size
      this.totals.table_pdfo_3.charge_sum = round_number(this.totals.table_pdfo_3.charge_sum, 2)
      this.totals.table_pdfo_3.charge_sum_withheld = round_number(this.totals.table_pdfo_3.charge_sum_withheld, 2)
      this.totals.table_pdfo_3.pdfo_charge_sum = round_number(this.totals.table_pdfo_3.pdfo_charge_sum, 2)
      this.totals.table_pdfo_3.pdfo_charge_sum_withheld = round_number(this.totals.table_pdfo_3.pdfo_charge_sum_withheld, 2)
      this.totals.table_pdfo_3.military_charge_sum = round_number(this.totals.table_pdfo_3.military_charge_sum, 2)
      this.totals.table_pdfo_3.military_charge_sum_withheld = round_number(this.totals.table_pdfo_3.military_charge_sum_withheld, 2)
    },
    getTotalsD1_1() {
      this.$nextTick(() => {
        this.calcTotalsD1_1()
      })
    },
    getTotalsD1_2() {
      this.$nextTick(() => {
        this.calcTotalsD1_2()
      })
    },
    getTotalsD1_3() {
      this.$nextTick(() => {
        this.calcTotalsD1_3()
      })
    },
    getTotalsD4_1() {
      this.$nextTick(() => {
        this.calcTotalsD4_1()
      })
    },
    getTotalsD4_2() {
      this.$nextTick(() => {
        this.calcTotalsD4_2()
      })
    },
    getTotalsD4_3() {
      this.$nextTick(() => {
        this.calcTotalsD4_3()
      })
    },
    add_table_5_row() {
      const max_row_item = maxBy(this.table_hire_release, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        contract_cln: 0,
        date_end: null,
        date_start: null,
        document_hire_reason: "",
        document_release_reason: "",
        first_name: "",
        full_name: "",
        inner_combination: 0,
        inner_work: 0,
        ipn: "",
        last_name: "",
        middle_name: "",
        millitary_position_name: "",
        national: null,
        new_work_place: false,
        new_work_place_date: null,
        person_category: null,
        position_code_kp: null,
        position_code_zkpptr: null,
        position_name: null,
        position_name_kp: null,
        row_sign: null,
        row_num: max_row + 1
      }

      this.table_hire_release.push(new_row)
    },
    add_table_4_3_row() {
      const max_row_item = maxBy(this.table_pdfo_3, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        accept_date: null,
        charge_sum: null,
        charge_sum_withheld: null,
        first_name: "",
        full_name: "",
        ipn: "",
        last_name: "",
        middle_name: "",
        military_charge_sum: null,
        military_charge_sum_withheld: null,
        pdfo_charge_sum: null,
        pdfo_charge_sum_withheld: null,
        privilege_code: null,
        profit_code: "101",
        release_date: null,
        sign_row:null,
        row_num: max_row + 1
      }

      this.table_pdfo_3.push(new_row)
    },
    add_table_4_2_row() {
      const max_row_item = maxBy(this.table_pdfo_2, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        accept_date: null,
        charge_sum: null,
        charge_sum_withheld: null,
        first_name: "",
        full_name: "",
        ipn: "",
        last_name: "",
        middle_name: "",
        military_charge_sum: null,
        military_charge_sum_withheld: null,
        pdfo_charge_sum: null,
        pdfo_charge_sum_withheld: null,
        privilege_code: null,
        profit_code: "101",
        release_date: null,
        sign_row:null,
        row_num: max_row + 1
      }

      this.table_pdfo_2.push(new_row)
    },
    add_table_4_1_row() {
      const max_row_item = maxBy(this.table_pdfo_1, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        accept_date: null,
        charge_sum: null,
        charge_sum_withheld: null,
        first_name: "",
        full_name: "",
        ipn: "",
        last_name: "",
        middle_name: "",
        military_charge_sum: null,
        military_charge_sum_withheld: null,
        pdfo_charge_sum: null,
        pdfo_charge_sum_withheld: null,
        privilege_code: null,
        profit_code: "101",
        release_date: null,
        sign_row:null,
        row_num: max_row + 1
      }

      this.table_pdfo_1.push(new_row)
    },
    add_table_1_3_row() {
      const max_row_item = maxBy(this.table_esv_3, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        base: 0,
        base_diff: 0,
        category_code: 1,
        charge_type: "",
        decree_days: 0,
        first_name: "",
        full_name: "",
        gender: "Ч",
        hospital_days: 0,
        ipn: "",
        last_name: "",
        main: true,
        main_days: 0,
        middle_name: "",
        month: null,
        month_number: null,
        national: 1,
        new_work_place: 0,
        not_full_time: 0,
        person_id: null,
        result: 0,
        row_key: max_row + 1,
        sign_row: "",
        special_experience: 0,
        withheld_sum: 0,
        without_days: 0,
        work_book: 1,
        year_number: null,
        contract_with_not_full_job: 0,
        row_num: max_row + 1
      }

      this.table_esv_3.push(new_row)
    },
    add_table_1_2_row() {
      const max_row_item = maxBy(this.table_esv_2, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        base: 0,
        base_diff: 0,
        category_code: 1,
        charge_type: "",
        decree_days: 0,
        first_name: "",
        full_name: "",
        gender: "Ч",
        hospital_days: 0,
        ipn: "",
        last_name: "",
        main: true,
        main_days: 0,
        middle_name: "",
        month: null,
        month_number: null,
        national: 1,
        new_work_place: 0,
        not_full_time: 0,
        person_id: null,
        result: 0,
        row_key: max_row + 1,
        sign_row: "",
        special_experience: 0,
        withheld_sum: 0,
        without_days: 0,
        work_book: 1,
        year_number: null,
        contract_with_not_full_job: 0,
        row_num: max_row + 1
      }

      this.table_esv_2.push(new_row)
    },
    add_table_1_1_row() {
      const max_row_item = maxBy(this.table_esv_1, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }

      const new_row = {
        base: 0,
        base_diff: 0,
        category_code: 1,
        charge_type: "",
        decree_days: 0,
        first_name: "",
        full_name: "",
        gender: "Ч",
        hospital_days: 0,
        ipn: "",
        last_name: "",
        main: true,
        main_days: 0,
        middle_name: "",
        month: null,
        month_number: null,
        national: 1,
        new_work_place: 0,
        not_full_time: 0,
        person_id: null,
        result: 0,
        row_key: max_row + 1,
        sign_row: "",
        special_experience: 0,
        withheld_sum: 0,
        without_days: 0,
        work_book: 1,
        year_number: null,
        contract_with_not_full_job: 0,
        row_num: max_row + 1
      }

      this.table_esv_1.push(new_row)
    },
    delete_row_d5(row) {
      const idx = this.table_hire_release.indexOf(row)
      if (idx !== -1 ) {
        this.table_hire_release.splice(idx, 1)
      }
    },
    delete_row_d4_3(row) {
      const idx = this.table_pdfo_3.indexOf(row)
      if (idx !== -1 ) {
        this.table_pdfo_3.splice(idx, 1)
      }
      this.getTotalsD4_3()
    },
    delete_row_d4_2(row) {
      const idx = this.table_pdfo_2.indexOf(row)
      if (idx !== -1 ) {
        this.table_pdfo_2.splice(idx, 1)
      }
      this.getTotalsD4_2()
    },
    delete_row_d4_1(row) {
      const idx = this.table_pdfo_1.indexOf(row)
      if (idx !== -1 ) {
        this.table_pdfo_1.splice(idx, 1)
      }
      this.getTotalsD4_1()
    },
    delete_row_d1_3(row) {
      const idx = this.table_esv_3.indexOf(row)
      if (idx !== -1 ) {
        this.table_esv_3.splice(idx, 1)
      }
      this.getTotalsD1_3()
    },
    delete_row_d1_2(row) {
      const idx = this.table_esv_2.indexOf(row)
      if (idx !== -1 ) {
        this.table_esv_2.splice(idx, 1)
      }
      this.getTotalsD1_2()
    },
    delete_row_d1_1(row) {
      const idx = this.table_esv_1.indexOf(row)
      if (idx !== -1 ) {
        this.table_esv_1.splice(idx, 1)
      }
      this.getTotalsD1_1()
    },
    questionForDeleteReport() {
      const payload = {
        text: `Ви дійсно хочете видалити звіт?`,
        accept_button: true,
        id: `delete-${this.report_id}`
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    delete_report() {
      const payload = {
        report_type: 'esv_declaration',
        report_id: this.report_id
      }
      regulatoryAPI.delete_report(payload)
          .then(response => response.data)
          .then(data => {
            this.$emit('reportDelete', data)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    watch_modal_answer() {
      this.modal_answer_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === `delete-${this.report_id}`) {
                if (payload.answer) {
                  this.delete_report()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.watch_modal_answer()
    if (!this.report_is_new && this.report_id) {
      regulatoryAPI.get_report({report_id: this.report_id, report_type: this.report_type})
          .then(response => response.data)
          .then(data => {
            this.save_data_after_fetch(data.report_data, false)
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    }
  },
  beforeDestroy() {
    this.header = {}
    this.declaration = {}
    this.dtable_esv_1 = []
    this.dtable_esv_2 = []
    this.dtable_esv_3 = []
    this.dtable_pdfo_1 = []
    this.dtable_pdfo_2 = []
    this.dtable_pdfo_3 = []
    this.dtable_hire_release = []
    if (this.modal_answer_watcher) {
      this.modal_answer_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.regulatory-report-input {
  outline: none;
  line-height: inherit;
  width: 100%;
  border-bottom: 1px solid #4CAF50;
  margin-bottom: 3px;
}

.error-input {
  border-bottom: 1px solid var(--v-error-base);
}

.regulatory-report-table {
  width: 1300px;
  font-family: Arial, Calibri, serif;
  padding: 4px 12px;
  float: none !important;
  font-size: 11px;
  border-collapse: collapse;

  thead {
    tr {
      border: 1px solid grey;

      th {
        border: 1px solid grey;
        padding: 2px 6px;
        font-size: 10px;
        font-weight: bold;
        line-height: 14px;
      }
    }

    .without-border {
      border: none !important;
    }
  }

  tbody {
    tr {
      border: 1px solid grey;

      td {
        border: 1px solid grey;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 16px;
      }

      .with-border {
        border: 1px solid grey;
      }
    }

    .without-border {
      border: none !important;
    }

    .total {
      border: none !important;

      td {
        font-weight: bold !important;
        font-size: .9rem;
        border: none !important;
      }
    }
  }
}
</style>